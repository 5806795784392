import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout"
import {Container, Row} from 'reactstrap'
import PageHeader from '../../components/pageHeader'
import PageStyling from '../../components/pageStyling'
import Post from '../../components/post'

export default ({data}) => (
  <Layout title="NEWS">
    <PageHeader title="NEWS"/>
    <Container className="py-5">
      <PageStyling>
        <h2 className="text-primary">NEWS</h2>
        <div className="mb-4">
          <h5 className="text-primary">If you’re keen to learn more about working in Australia, why not enquire today and start the ball rolling?</h5>
          <p>The way we work is a lot different to conventional recruiters. We know first hand that this is a massive decision and we’re also aware of the perceived complexities of emigrating abroad. It is for this reason we aim to provide a highly personalised service. We consider ourselves as consultants or partners working with you by simplifying the process so as to ensure you avoid any potential pitfalls.</p>
        </div>
      </PageStyling>
      <Row>
        {data.allWordpressPost.edges.filter(p => p.node.featured_media.localFile != null).map((post, i) => (
          <Post post={post} key={i}/>
        ))}
      </Row>
    </Container>
  </Layout>
)


export const pageQuery = graphql`
  query {
    allWordpressPost(filter: {categories: {eq: 37}}) {
      edges {
        node {
          title
          excerpt
          path
          featured_media {
            localFile {
              childImageSharp {
                fixed(height: 250, width: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
